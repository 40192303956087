import React from "react";

function Footer() {
    return (
        <div className="wrapper">
            <footer>
                <span>Copyright 2020 chiatek.com</span>
            </footer>
        </div>
    );
}

export default Footer;