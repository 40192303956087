import React from "react";
import Section from "../sections/Section";
import Cta from "../sections/Cta";
import Projects from "../sections/Projects";
import Features from "../sections/Features";
import Footer from "../sections/Footer";

function Videography() {
    return (
        <div>
            <div className="hero-banner"></div>
            <Section id="videography" name="videography" />
            <Cta id={1} />
            <Section id="services" name="videography-services" />
            <Features index={0} />
            <Projects category="videography" />
            <Footer />
        </div>
    );
}

export default Videography;