import React from "react";
import Section from "../sections/Section";
import Cta from "../sections/Cta";
import Projects from "../sections/Projects";
import Features from "../sections/Features";
import Footer from "../sections/Footer";

function Webdesign() {
    return (
        <div>
            <div className="hero-banner"></div>
            <Section id="webdesign" name="webdesign" />
            <Cta id={2} />
            <Section id="services" name="webdesign-services" />
            <Features index={1} />
            <Projects category="webdesign" />
            <Footer />
        </div>
    );
}

export default Webdesign;